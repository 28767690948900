.qualification_container {
    max-width: 768px;
}

.qualification_tabs {
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.qualification_button {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}

.qualification_button:hover {
    color: var(--title-color-dark);
}

.qualification_icon {
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification_active {
    color: var(--title-color-dark);
}

.qualification_sections {
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}


.qualification_content {
    display: none;
}

.qualification_content-active {
    display: block;
}

.qualification_data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification_title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification_subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification_calendar {
    font-size: var(--small-font-size);
}

.qualification_rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualification_line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

.qualification_experience {
    text-align: center;
    color: var(--title-color);
}

/* for large devices */ 
@media screen and (max-width: 992px) {
    .qualification_container {
        margin-left: auto;
        margin-right: auto;
    }
}

/* for medium devices */
  @media screen and (max-width: 768px) {
    .qualification_container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
  }

  @media screen and (max-width: 576px) {
    .qualification_sections {
        grid-template-columns: initial;
    }

    .qualification_button {
        margin: 0 var(--mb-0-75);
    }
  }
  
  /* for small devices */
  @media screen and (max-width: 350px) {
    .qualification_data {
        gap: 0.5rem;
    }
  }